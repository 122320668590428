import drawer from "./drawer";

// TODO: offer specific APIs for search, cart and account in addition to the generic drawer api.

const b2cApi = {
  drawer,
};

export default b2cApi;
export { drawer };
