function open(name: string): void {
  const event = new CustomEvent("open-drawer", {
    detail: {
      name,
    },
  });
  window.dispatchEvent(event);
}

function close(): void {
  window.dispatchEvent(new CustomEvent("close-drawer"));
}

function prefetchDrawer(name: string): void {
  const event = new CustomEvent("prefetch-drawer", {
    detail: {
      name,
    },
  });
  window.dispatchEvent(event);
}

const drawer = {
  open,
  close,
  prefetchDrawer,
};

export default drawer;
